import React from 'react';
import GoogleMapReact from 'google-map-react';
import MarkerCustom from './MarkerCustom';

const DoanGoogleMap = ({ apikey, doanList, selected }) => (
  <div style={{ height: '400px', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: apikey }}
      defaultCenter={{ lat: 39.8097343, lng: -98.5556199 }}
      defaultZoom={3}
    >
      {doanList.map(
        ({
          Latitude,
          Longitude,
          id,
          Chapter,
          State,
          Address,
          League,
          City,
        }) => (
          <MarkerCustom
            lat={Latitude}
            lng={Longitude}
            key={id}
            chapter={Chapter}
            divid={State.concat(Chapter.replace(/\s+/g, '')).concat(id)}
            address={Address}
            league={League}
            city={City}
            state={State}
            selected={id === selected}
          />
        )
      )}
    </GoogleMapReact>
  </div>
);
export default DoanGoogleMap;
