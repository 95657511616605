import React from 'react';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';

const MarkerCustom = ({
  $hover,
  divid,
  chapter,
  address,
  league,
  city,
  state,
  selected,
}) => {
  const K_SIZE = selected ? 30 : 18;

  let dotColor = '#ffffff';
  switch (league) {
    case 'Phaolô Hạnh':
      dotColor = '#ad78af';
      break;
    case 'Đaminh Saviô':
      dotColor = '#fda72c';
      break;
    case 'Joan of Arc':
      dotColor = '#2b968e';
      break;
    case 'John Paul II':
      dotColor = '#2b71c0';
      break;
    case 'Biển Đức':
      dotColor = '#eacb2e';
      break;
    case 'Sinai':
      dotColor = '#ad6329';
      break;
    case 'San Diego':
      dotColor = '#21c5d2';
      break;
    case 'Ra Khơi':
      dotColor = '#e87c9c';
      break;
    case 'Nguồn Sống':
      dotColor = '#d2d2d2';
      break;
    case 'Ignatius Loyola':
      dotColor = '#71ac4c';
      break;
    case 'Holy Family':
      dotColor = '#fc6645';
      break;
    default:
      dotColor = '#ffffff';
  }

  const markerStyle = {
    // initially any map object has left top corner at lat lng coordinates
    // it's on you to set object origin to 0,0 coordinates
    position: 'absolute',
    width: K_SIZE,
    height: K_SIZE,
    left: -K_SIZE / 2,
    top: -K_SIZE / 2,

    // border: '4px solid #f44336',
    border: '2px solid #f24030',
    // borderColor: selected ? '#343deb' : '#f24030',
    borderRadius: K_SIZE,
    backgroundColor: selected ? '#aeeb34' : dotColor,
    textAlign: 'center',
    color: '#3f51b5',
    fontSize: 16,
    fontWeight: 'bold',
    padding: 4,
    cursor: 'pointer',
  };

  const markerStyleHover = {
    ...markerStyle,
    border: '2px solid #3f51b5',
    // borderColor: selected ? '#343deb' : '#3f51b5',
    color: '#f44336',
  };

  const style = $hover ? markerStyleHover : markerStyle;

  return (
    <div>
      <div style={style} id={divid}>
        {/* {props.text} */}
      </div>
      <UncontrolledPopover trigger="legacy" placement="bottom" target={divid}>
        <PopoverHeader>{`Đoàn ${chapter}`}</PopoverHeader>
        <PopoverBody>
          <div>
            {`Liên Đoàn ${league}`}
            <br />
            {address}
            <br />
            {`${city}, ${state}`}
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

export default MarkerCustom;
