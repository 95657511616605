import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
} from 'reactstrap';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import TitleBar from '../../components/TitleBar/TitleBar';
import PageHeading from '../../components/PageHeading/PageHeading';
import DoanGoogleMap from '../../components/DoanGoogleMap/DoanGoogleMap';
import styles from '../../css/aboutus.module.css';

const key = 'AIzaSyCd_rqzd1o1oH7dVYJgrrddAZDi5JQ-mBg';

class ListOfDoan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterString: '',
      selected: '',
    };
  }

  handleChange = event => {
    this.setState({ filterString: event.target.value });
  };

  clearFilter = () => {
    this.setState({ filterString: '' });
  };

  handleSelect = id => {
    if (this.state.selected === id) {
      this.setState({ selected: '' });
    } else {
      this.setState({ selected: id });
    }
  };

  abbrState = (input, to) => {
    var states = [
      ['Arizona', 'AZ'],
      ['Alabama', 'AL'],
      ['Alaska', 'AK'],
      ['Arizona', 'AZ'],
      ['Arkansas', 'AR'],
      ['California', 'CA'],
      ['Colorado', 'CO'],
      ['Connecticut', 'CT'],
      ['Delaware', 'DE'],
      ['Florida', 'FL'],
      ['Georgia', 'GA'],
      ['Hawaii', 'HI'],
      ['Idaho', 'ID'],
      ['Illinois', 'IL'],
      ['Indiana', 'IN'],
      ['Iowa', 'IA'],
      ['Kansas', 'KS'],
      ['Kentucky', 'KY'],
      ['Kentucky', 'KY'],
      ['Louisiana', 'LA'],
      ['Maine', 'ME'],
      ['Maryland', 'MD'],
      ['Massachusetts', 'MA'],
      ['Michigan', 'MI'],
      ['Minnesota', 'MN'],
      ['Mississippi', 'MS'],
      ['Missouri', 'MO'],
      ['Montana', 'MT'],
      ['Nebraska', 'NE'],
      ['Nevada', 'NV'],
      ['New Hampshire', 'NH'],
      ['New Jersey', 'NJ'],
      ['New Mexico', 'NM'],
      ['New York', 'NY'],
      ['North Carolina', 'NC'],
      ['North Dakota', 'ND'],
      ['Ohio', 'OH'],
      ['Oklahoma', 'OK'],
      ['Oregon', 'OR'],
      ['Pennsylvania', 'PA'],
      ['Rhode Island', 'RI'],
      ['South Carolina', 'SC'],
      ['South Dakota', 'SD'],
      ['Tennessee', 'TN'],
      ['Texas', 'TX'],
      ['Utah', 'UT'],
      ['Vermont', 'VT'],
      ['Virginia', 'VA'],
      ['Washington', 'WA'],
      ['West Virginia', 'WV'],
      ['Wisconsin', 'WI'],
      ['Wyoming', 'WY'],
    ];

    if (to === 'abbr') {
      input = input.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
      for (let i = 0; i < states.length; i++) {
        if (states[i][0] === input) {
          return states[i][1];
        }
      }
    } else if (to === 'name') {
      input = input.toUpperCase();
      for (let i = 0; i < states.length; i++) {
        if (states[i][1] === input) {
          return states[i][0];
        }
      }
    }
  };

  render() {
    const { data } = this.props;
    const { edges: doans } = data.allListOfDoanCsv;
    const mappedDoan = doans.map(doan => doan.node);
    const { filterString, selected } = this.state;

    const matchingDoan =
      filterString !== ''
        ? mappedDoan.filter(
            doan =>
              doan.League.normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .replace(/đ/gi, 'd')
                .includes(
                  filterString
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
                    .replace(/đ/gi, 'd')
                ) ||
              doan.Chapter.normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .replace(/đ/gi, 'd')
                .includes(
                  filterString
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
                    .replace(/đ/gi, 'd')
                ) ||
              doan.Address.toLowerCase().includes(filterString.toLowerCase()) ||
              doan.City.toLowerCase().includes(filterString.toLowerCase()) ||
              doan.State.toLowerCase().includes(filterString.toLowerCase()) ||
              this.abbrState(doan.State, 'name')
                .toLowerCase()
                .includes(filterString.toLowerCase())
          )
        : mappedDoan;

    return (
      <Layout>
        <Helmet
          title="VEYM | List of Chapters"
          meta={[
            {
              name: 'description',
              content: `List and location of the chapters (Đoàn) in VEYM-USA`,
            },
          ]}
        />
        <PageHeading>List of Chapters</PageHeading>

        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/aboutus">About</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>List of Chapters</BreadcrumbItem>
          </Breadcrumb>
        </Container>

        <br />

        <br />
        <br />

        <TitleBar>List of Chapters</TitleBar>
        <br />
        <Container>
          <InputGroup>
            <InputGroupAddon addonType="prepend">Filter:</InputGroupAddon>
            <Input
              placeholder="Type here"
              value={this.state.filterString}
              onChange={this.handleChange}
            />
            <InputGroupAddon addonType="append">
              <Button color="secondary" onClick={() => this.clearFilter()}>
                Clear
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <br />
          <DoanGoogleMap
            apikey={key}
            doanList={matchingDoan}
            selected={selected}
          />

          <br />
          {filterString === '' ? (
            <h6>
              Total Chapters:&nbsp;
              {mappedDoan.length}
            </h6>
          ) : (
            <h6>
              Matching Chapters:&nbsp;
              {matchingDoan.length}
            </h6>
          )}
          <br />

          <div className={styles.doanTable}>
            <Table striped hover responsive>
              <thead>
                <tr>
                  {/* <th>ID</th> */}
                  <th>League</th>
                  <th>Chapter</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>State</th>
                </tr>
              </thead>
              <tbody>
                {matchingDoan.map(
                  ({ id, League, Chapter, Address, City, State }) => (
                    <tr
                      key={`${id}-${Chapter}`}
                      className={id === selected ? styles.selected : ''}
                      onClick={() => this.handleSelect(id)}
                    >
                      {/* <td>{id}</td> */}
                      <td>{League}</td>
                      <td>
                        <b>{Chapter}</b>
                      </td>
                      <td>{Address}</td>
                      <td>{City}</td>
                      <td>{State}</td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>

          <br />

          <br />
        </Container>
      </Layout>
    );
  }
}

ListOfDoan.propTypes = {
  data: PropTypes.shape({
    allListOfDoanCsv: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default ListOfDoan;

export const frontmatter = {
  title: 'List of Chapters',
  category: 'pages',
  path: '/aboutus/listofdoan',
  description: 'List of Chapters',
  tags: 'list, doan, map, google, chapters, locations, ',
};

export const pageQuery = graphql`
  query DoanListQuery {
    allListOfDoanCsv(sort: { fields: [League], order: ASC }) {
      edges {
        node {
          id
          League
          Chapter
          Address
          City
          State
          Latitude
          Longitude
        }
      }
    }
  }
`;
